/* * {
  transition: all 0.3s ease;
} */
.listContainer {
  width: 100%;
  height: auto;
}
.listItem {
  width: 100%;
  min-height: 70px;
  background-color: white;
  margin: 10px 0px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  border-radius: 3px;
}

.row {
  cursor: pointer;
}
.row:hover {
  background-color: var(--darkBlue);
}

/*List item styling */

/*Player Info*/
.playerInfo {
  width: 30%;
  height: 100%;
  border-right: 2px solid rgba(0, 0, 0, 0.14);
  text-align: center;
  min-height: 70px;
  position: relative;
}
.playerInfoContent {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.challengeName {
  font-size: 15px;
  letter-spacing: 1px;
  color: #292929;
  font-weight: 400;
}
.players {
  color: #302f2f;
  margin: 2px 7px;
  font-weight: 200;
  display: inline-block;
}
.players,
.playerIcon {
  display: inline;
}
.playerIcon {
  position: relative;
  top: 3px;
  width: 19px !important;
  height: 19px !important;
}

/*Challenge Info */
.challengeInfo {
  /*width: 50%; <- for when there is remove button too*/
  width: 60%;
  height: 100%;
  border-right: 2px solid rgba(0, 0, 0, 0.14);
  min-height: 70px;
  display: flex;
}

.centre {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.languages {
  width: 30%;
  min-height: 70px;
  text-align: center;
  position: relative;
}
.languageItems {
  font-size: 12px;
  display: inline-block;
  font-weight: 300;
  margin: 0px 5px;
}
.progress {
  width: 70%;
  min-height: 70px;
  text-align: center;
  position: relative;
}
.actionButton {
  width: 10%;
  min-height: 70px;
  text-align: center;
  cursor: pointer;
}
.actionButton:hover > h2 {
  color: white;
  letter-spacing: 4px;
}
.actionButton h2 {
  font-size: 15px;
  margin-top: 20%;
}
.join:hover {
  background-color: #23ddae;
}
.remove:hover {
  background-color: #e66854;
}
.icon {
  display: block;
  margin: auto;
}
.paper {
  background-color: #b6feff6e;
}
.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
}
.iconHeader {
  background-color: #00b57999;
}
.iconH {
  text-align: center;
}
.icons {
  color: var(--darkWhite);
}
.text {
  color: var(--darkWhite);
}
.center {
  text-align: center;
}
.selected {
  background-color: var(--mainBlue) !important;
}

.joinButton {
  position: absolute;
  bottom: 0;
  right: -8rem;
  background-color: var(--mainGreen);
  color: var(--darkWhite);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;

  padding: 1rem;
  transition: transform 0.4s linear;
}

.joinButton:hover {
  background-color: #000e2fa6;
}

.popIn {
  transform: translateX(-200%) !important;
}

.popIn:hover {
  background-color: var(--darkBlue);
}

.last {
  position: relative;
  overflow: hidden;
}
