.infoBox {
  margin: 25px 0;
  border-radius: 10px;
  color: white;
  text-align: center;
  font-size: 1.2rem;
  background-color: #00b17dbd;
  padding: 0.8rem;
}

.infoBox p {
  padding-top: 6px;
  padding-bottom: 6px;
}

.kickstarterContent {
  display: inline-block;
  background-color: #009f7f;
  padding: 15px 20px;

  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.kickstarterButtonA {
  font-weight: bold;
  padding: 7px 35px;
  border: 2px solid white !important;
  border-radius: 10px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 10px;
}
.kickstarterButtonA:hover {
  background-color: white;
  color: #14e06e;
}
.kickstarterButtonB {
  font-weight: bold;
  padding: 7px 35px;
  border: 2px solid #14e06e !important;
  border-radius: 10px;
  color: #14e06e;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.kickstarterButtonB:hover {
  background-color: #14e06e;
  color: white;
}
.kickstarterContent img {
  height: 30px;
  padding: 0 5px;
}
.kickstarterContent h1 {
  color: var(--darkWhite);
}
.kickstarterContent h2 {
  color: white;
}
.container {
  background-image: url("/images/patterns/stars.png");
  background-size: contain;
  padding-bottom: 1rem;
}
.top {
  display: flex;
  padding-top: 1rem;
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.textHeader {
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
