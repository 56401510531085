.paper {
  width: 80vw;
  height: 80vh;
  max-width: unset;
  background-color: var(--darkWhite);
  color: var(--darkBlue);
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.title {
  color: inherit;
  font-size: 2rem;
  width: 80%;
  display: flex;
  justify-content: center;
}
.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 60%;
  flex-wrap: wrap;
}
.actions {
  width: 100%;
  justify-content: center;
}
.textfieldContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.textfield {
  width: 50%;
}
.files {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.friendsContainer,
.sendContainer,
.pendingContainer,
.requestsContainer {
  height: 48%;
  width: 35%;

  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sendContainer {
  justify-content: space-between;
}

.span {
  margin-top: 5rem;
  color: #8080808f;
}
