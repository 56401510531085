@import url(https://fonts.googleapis.com/css?family=Varela+Round);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,
  400,
  600,
  700);
:root {
  --mainBlue: #4a90e2;
  --lightBlue: ;
  --blue: #001c55;
  --darkBlue: #000e2f;

  --mainGreen: #00b17d;
  --lightGreen: ;
  --darkGreen: ;

  --black: #000818;

  --lightWhite: #f7f7f7;
  --darkWhite: #f2f2f2;

  --grey: #d8d8d8;

  --red: #cc0000;
}

/* Fonts */
p,
h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", sans-serif;
}

/*ColonelDuck*/
body {
  margin: 0;
  background-color: #e3e3e3;
  font-family: "Montserrat", sans-serif;
  /*"Helvetica Neue"*/
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0px;
  padding: 0px;
}

.background {
  background-image: url(/images/patterns/stars.png);
  background-size: contain;
}

.fill-height {
  height: 100%;
}

/**
  GameContainer
*/
.game_container {
  display: flex;
  height: 93vh;
}

/**
  AreaHeader
*/
.area-heading {
  display: flex;
  align-items: center;
  color: #e48414;
  background-color: #ffffff;
  justify-content: center;
  margin: 0;
  padding: 10px 15px;
  box-sizing: border-box;
  height: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/**
  AreaFooter
*/
.area-footer {
  background-color: #ffffff;
  color: #e48414;
  margin: 0;
  padding: 5px 18px;
  box-sizing: border-box;
  height: 60px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/**
  PlayerArea
*/
.player_area {
  display: flex;
  width: 80%;
}

.layout_area {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: stretch;
}

.layout_area_row {
  flex-direction: row !important;
}

.layout_area_row .layout_area_child:nth-child(2) {
  order: -1;
}

.layout_area_child {
  flex: 1 1 50%;
  align-self: stretch;
}

/**
  PlayerWindow
*/
.one_player_window {
  width: 100%;
}

.player_window {
  width: 50%;
}

.player_window-content_area {
  height: calc(100% - 120px);
}

.player_header-name {
  font-size: 30px;
  margin-right: 8px;
  font-family: "Fjalla One", sans-serif;
}

/**
  PlayerFooter
*/
.player_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.player_footer-centred {
  justify-content: center;
}

.player_footer-run_button {
  margin-right: 2px;
}

/**
  PreviewWindow
*/
.preview_window {
  position: relative;
  height: 100%;
}

.preview_window-web {
  height: 100%;
}

.preview_window-web-frame {
  position: absolute;
  height: 100%;
  width: 100%;
  border: none;
}

.preview_window-console {
  overflow-y: auto;
}

/**
  InformationArea
*/
/*TODO: Check flexBasis support*/
.information_area {
  width: 20%;
  overflow-x: hidden;
  overflow-y: hidden;
}

._area-section {
  height: calc(100% - 120px);
  overflow-y: scroll;
}

.information_area-footer {
  background-repeat: no-repeat;
  background-size: auto 90%;
  background-position: center;
  background-image: url("/public/images/logo.png");
}

/*Setup scrollbar origin state*/
/*TODO: unfortunately, it seems like transition doesn't work on these selectors..*/
/*This approach was tried on normal CSS selectors and worked fine..*/
/*e.g. http://jsfiddle.net/derekstory/fTAUk/4/*/
.information_area-section::-webkit-scrollbar-thumb,
.chat_window-main_area::-webkit-scrollbar-thumb {
  background-color: rgba(2, 168, 243, 0.5);
}

.information_area-section::-webkit-scrollbar,
.chat_window-main_area::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.information_area-section::-webkit-scrollbar-track,
.chat_window-main_area::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.information_area-section pre::-webkit-scrollbar-thumb {
  background-color: rgba(2, 168, 243, 0.5);
}

.information_area-section pre::-webkit-scrollbar {
  height: 8px;
  background-color: #f5f5f5;
}

.information_area-section pre::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

/**
  Information
*/
.information {
  margin: 0 10px 0 10px;
}

.information-instruction {
  border: 1px dodgerblue solid;
  margin: 12px;
  padding: 0 5px;
}

/*For markdown*/
.information pre {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  word-wrap: normal;
  padding: 2px 5px;
  border-radius: 3px;
}

.information p code {
  padding: 2px 4px;
  font-size: 90%;
  color: #3f3f3f;
  background-color: #f8f8f8;
  white-space: nowrap;
  border-radius: 4px;
}

.information table {
  display: block;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.information tr {
  background-color: #fff;
  border-top: 1px solid black;
}

.information tr:nth-child(2n) {
  /*background-color: #f8f8f8;*/
}

.information th,
td {
  padding: 6px 13px;
  border: 1px solid black;
}

/*end - For markdown*/

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 100%;
  padding: 10px 20px 10px 40px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container--open {
  position: absolute;
  top: 51px;
  left: -28px;
  width: 500px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0;
  list-style-type: none;
  height: 50px;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #0c7eaf;
  color: #fff;
}

.suggestion-container {
}

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  height: 100%;
}

.react-autosuggest__suggestion img {
  height: 50px;
}
.suggestion-content > img {
  height: 50px;
}

.name {
  margin-left: 12px;
  line-height: 45px;
}

.react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}
.react-autosuggest__suggestions-list {
  padding: 0;
}

.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #777;
}

/** Invite Button **/

.navbar_logo__3mlpT {
  height: 4vh;
}
.navbar_icon__4WSn1 {
  color: var(--darkBlue);
}
.navbar_content__21kxe {
  flex-grow: 1;
}
.navbar_button__1h5_W {
  color: white;
}
.navbar_title__2D0Kl {
  text-align: center;
  font-size: 14px;
}
.navbar_nav__MRT1J {
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  width: 96%;
  text-decoration: none;
}
.navbar_active___HXdn {
  text-decoration: none;
}
.navbar_drawer__3J1eX {
  width: 30vw;
}

.navbar_linkList__k33Zw {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar_link__1zCni {
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  width: 91%;
  padding: 1rem;
  text-decoration: none;
}

.ChooseACharacter_container__2PwGn {
  height: 40vh;
  width: 25vw;
  box-sizing: border-box;
  margin: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  -webkit-filter: grayscale(0.7);
          filter: grayscale(0.7);
  overflow: hidden;
}

.ChooseACharacter_imgSection__rlCuI {
  flex: 1 0 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ChooseACharacter_actionSection__391ZM {
  position: relative;
  text-decoration: none;
  flex: 1 0 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ChooseACharacter_container__2PwGn:nth-child(4n + 1) {
  background-image: -webkit-gradient(
    linear,
    left bottom, right top,
    from(#6882c0),
    color-stop(#5f6aa9),
    color-stop(#575292),
    color-stop(#4f3a79),
    to(#472260)
  );
  background-image: linear-gradient(
    to right top,
    #6882c0,
    #5f6aa9,
    #575292,
    #4f3a79,
    #472260
  );
}
.ChooseACharacter_container__2PwGn:nth-child(4n + 1):hover {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  background-image: -webkit-gradient(
    linear,
    left bottom, right top,
    from(#6882c0),
    color-stop(#5f6aa9),
    color-stop(#575292),
    color-stop(#4f3a79),
    to(#472260)
  );
  background-image: linear-gradient(
    to right top,
    #6882c0,
    #5f6aa9,
    #575292,
    #4f3a79,
    #472260
  );
}
.ChooseACharacter_container__2PwGn:nth-child(4n + 2):hover {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  background-image: -webkit-gradient(
    linear,
    left top, right bottom,
    from(#dd5668),
    color-stop(#e26454),
    color-stop(#e17642),
    color-stop(#d88a32),
    to(#ca9d29)
  );
  background-image: linear-gradient(
    to right bottom,
    #dd5668,
    #e26454,
    #e17642,
    #d88a32,
    #ca9d29
  );
}
.ChooseACharacter_container__2PwGn:nth-child(4n + 3):hover {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  background-image: -webkit-gradient(
    linear,
    right top, left bottom,
    from(#34285a),
    color-stop(#38296d),
    color-stop(#3b2a81),
    color-stop(#3e2b95),
    to(#402baa)
  );
  background-image: linear-gradient(
    to left bottom,
    #34285a,
    #38296d,
    #3b2a81,
    #3e2b95,
    #402baa
  );
}
.ChooseACharacter_container__2PwGn:nth-child(4n + 4):hover {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  background-image: -webkit-gradient(
    linear,
    left bottom, left top,
    from(#ff6a5b),
    color-stop(#eb5161),
    color-stop(#d33b67),
    color-stop(#b7296a),
    to(#981c6b)
  );
  background-image: linear-gradient(
    to top,
    #ff6a5b,
    #eb5161,
    #d33b67,
    #b7296a,
    #981c6b
  );
}
.ChooseACharacter_container__2PwGn:nth-child(4n + 2) {
  background-image: -webkit-gradient(
    linear,
    left top, right bottom,
    from(#dd5668),
    color-stop(#e26454),
    color-stop(#e17642),
    color-stop(#d88a32),
    to(#ca9d29)
  );
  background-image: linear-gradient(
    to right bottom,
    #dd5668,
    #e26454,
    #e17642,
    #d88a32,
    #ca9d29
  );
}
.ChooseACharacter_container__2PwGn:nth-child(4n + 3) {
  background-image: -webkit-gradient(
    linear,
    right top, left bottom,
    from(#34285a),
    color-stop(#38296d),
    color-stop(#3b2a81),
    color-stop(#3e2b95),
    to(#402baa)
  );
  background-image: linear-gradient(
    to left bottom,
    #34285a,
    #38296d,
    #3b2a81,
    #3e2b95,
    #402baa
  );
}
.ChooseACharacter_container__2PwGn:nth-child(4n + 4) {
  background-image: -webkit-gradient(
    linear,
    left bottom, left top,
    from(#ff6a5b),
    color-stop(#eb5161),
    color-stop(#d33b67),
    color-stop(#b7296a),
    to(#981c6b)
  );
  background-image: linear-gradient(
    to top,
    #ff6a5b,
    #eb5161,
    #d33b67,
    #b7296a,
    #981c6b
  );
}

.ChooseACharacter_selected__2jGG- {
  border: 5px solid var(--mainGreen);
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
}

.ChooseACharacter_character__36XYq {
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: -webkit-transform 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transform: scale(1);
          transform: scale(1);
}

.ChooseACharacter_characterSelected__IQa0G {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.ChooseACharacter_goPlay__DiK7_ {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--mainGreen);
  color: var(--darkWhite);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;

  padding: 1rem;
  -webkit-transition: -webkit-transform 0.3s linear;
  transition: -webkit-transform 0.3s linear;
  transition: transform 0.3s linear;
  transition: transform 0.3s linear, -webkit-transform 0.3s linear;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.ChooseACharacter_goPlay__DiK7_:hover {
  background-color: var(--blue);
}

.ChooseACharacter_popIn__3BHrC {
  -webkit-transform: translateX(0) !important;
          transform: translateX(0) !important;
}

.ChooseACharacter_name__bSdQR {
  color: var(--darkWhite);

  font-size: 2rem;
}
/*
#6882c0
#773aa1

#5c41b8
#5947b2

#dd5668
#ca9d29

#ff6a5b
#981c6b */

.Pregame_container__31oyC {
  display: flex;
  flex-wrap: wrap;
  /* width: 80%; */
  margin: 0 auto;
  padding-top: 4rem;
  min-height: 100%;
}
.Pregame_page__3f2-Y {
  width: 100vw;
  min-height: 100vh;
  background-color: var(--darkBlue);
}
.Pregame_header__3a3CC {
  width: 100%;
  height: 7vh;
  background-color: var(--mainBlue);
  color: var(--darkWhite);
  margin-left: 1%;
  margin-right: 1%;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  padding-left: 4%;
}
.Pregame_caption__1XSqM {
  width: 100%;
  height: 7vh;
  margin-left: 1%;
  margin-right: 1%;
  display: flex;
  align-items: center;
  color: var(--darkWhite);
  padding-left: 4%;
}

.panel_text__2ErsV {
  paddingright: 20px;
}

.Information_information__3VN4e {
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
}

.Information_informationInstruction__1sUvk {
  border: 1px dodgerblue solid;
  margin: 12px;
  padding: 0 5px;
}

/*For markdown*/
.Information_information__3VN4e pre {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  word-wrap: normal;
  padding: 2px 5px;
  border-radius: 3px;
}

.Information_information__3VN4e p code {
  padding: 2px 4px;
  font-size: 90%;
  color: #3f3f3f;
  background-color: #f8f8f8;
  white-space: nowrap;
  border-radius: 4px;
}

.Information_information__3VN4e table {
  display: block;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.Information_information__3VN4e tr {
  background-color: #fff;
  border-top: 1px solid black;
}

.Information_information__3VN4e tr:nth-child(2n) {
  /*background-color: #f8f8f8;*/
}

.Information_information__3VN4e th,
td {
  padding: 6px 13px;
  border: 1px solid black;
}

.AreaHeader_areaHeading__1BBJG {
  display: flex;
  align-items: center;
  color: #e48414;
  background-color: #ffffff;
  justify-content: center;
  margin: 0;
  padding: 10px 15px;
  box-sizing: border-box;
  height: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.InformationArea_fillHeight__2jq9J {
  height: 100%;
}

.InformationArea_informationAreaSection__2N5D- {
  height: calc(100% - 120px);
  overflow-y: scroll;
}
.InformationArea_informationAreaSection__2N5D-::-webkit-scrollbar-thumb {
  background-color: rgba(2, 168, 243, 0.5);
}
.InformationArea_informationAreaSection__2N5D-::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
.InformationArea_informationAreaSection__2N5D-::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.InformationArea_informationAreaSection__2N5D- pre::-webkit-scrollbar-thumb {
  background-color: rgba(2, 168, 243, 0.5);
}
.InformationArea_informationAreaSection__2N5D- pre::-webkit-scrollbar {
  height: 8px;
  background-color: #f5f5f5;
}
.InformationArea_informationAreaSection__2N5D- pre::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.InformationArea_areaHeading__3ZKE8 {
  display: flex;
  align-items: center;
  color: #e48414;
  background-color: #ffffff;
  justify-content: center;
  margin: 0;
  padding: 10px 15px;
  box-sizing: border-box;
  height: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.InformationArea_informationAreaFooter__38GpE {
  background-repeat: no-repeat;
  background-size: auto 90%;
  background-position: center;
  background-image: url("/public/images/logo.png");
}

.Toolbelt_colorPicker__cVIhr {
  margin: 0 0 auto auto;
}

.PlayerHeader_areaHeading__UwbuG {
  display: flex;
  align-items: center;
  color: #e48414;
  background-color: #ffffff;
  justify-content: space-between;
  margin: 0;
  padding: 10px 15px;
  box-sizing: border-box;
  height: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.PlayerHeader_playerHeaderName__1oHw0 {
  font-size: 30px;
  margin-right: 8px;
  font-family: "Fjalla One", sans-serif;
}
.PlayerHeader_status__1eOSB {
  min-width: 95px;
}
.PlayerHeader_center__1k0dJ {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  justify-content: center;
}

/* The browser window */
.PreviewWindowBase_container__CC_qK {
  border: 3px solid #f1f1f1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 100%;
  overflow: hidden;
}

/* Container for columns and the top "toolbar" */
.PreviewWindowBase_row__3j0aS {
  padding: 10px;
  background: #f1f1f1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

/* Create three unequal columns that floats next to each other */
.PreviewWindowBase_column__lDMFR {
  float: left;
}

.PreviewWindowBase_left__YEZd- {
  width: 15%;
}

.PreviewWindowBase_right__3b6S2 {
  width: 10%;
}

.PreviewWindowBase_middle__1ALsK {
  width: 75%;
}

/* Clear floats after the columns */
.PreviewWindowBase_row__3j0aS:after {
  content: '';
  display: table;
  clear: both;
}

/* Three dots */
.PreviewWindowBase_dot__IJFMC {
  margin-top: 4px;
  height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.PreviewWindowBase_dot__IJFMC:first-child {
  background: #5ac05a;
}
.PreviewWindowBase_dot__IJFMC:nth-child(2) {
  background: #fdd800;
}
.PreviewWindowBase_dot__IJFMC:last-child {
  background: #ed594a;
}

/* Style the input field */
.PreviewWindowBase_urlField__114Aj {
  width: 100%;
  border-radius: 3px;
  border: none;
  background-color: white;
  margin-top: -2px;
  color: #666;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Three bars (hamburger menu) */
.PreviewWindowBase_bar__1Nm9C {
  width: 17px;
  height: 3px;
  background-color: #aaa;
  margin: 3px 0;
  display: block;
}

.PreviewWindowBase_menu__1dStn {
  float: right;
}

/* Page content */
.PreviewWindowBase_content__3qjGt {
  position: relative;
  height: calc(100% - 50px);
  width: 100%;
}

.ConsoleRender_fillHeight__12xBF {
  height: 100%;
}
.ConsoleRender_previewWindowConsole__1wero {
  overflow-y: auto;
}
.ConsoleRender_coding__3un3d {
  width: 100%;
  height: 0px;
  background-color: transparent;
}
.ConsoleRender_barIcon__1-EvX {
  width: 20px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  vertical-align: top;
  position: relative;
  /* top: 50%; */
  /* transform: translateY(-50%); */
  margin-left: 20px;
}
.ConsoleRender_statusText__2P2Vo {
  display: inline-block;
  position: relative;
  /* top: 50%; */
  /* transform: translateY(-50%); */
  margin-left: 10px;
  color: white;
  font-weight: 300;
  letter-spacing: 2px;
}
.ConsoleRender_codeOutput__1WjZ0 {
  color: var(--lightWhite);
  padding: 5px 0px 5px 20px;
  whitespace: pre-wrap;
}
.ConsoleRender_console__2G5F6 {
  background-color: var(--grey);
  border-right: 2px solid var(--darkWhite);
  border-left: 2px solid var(--darkWhite);
}
.ConsoleRender_baseBar__2YFUu {
  height: 3rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.ConsoleRender_output__1Z7DX {
  padding: 1rem;
}
.ConsoleRender_passed__qTWIk {
  background-color: var(--mainGreen);
}
.ConsoleRender_failed__2YPfR {
  background-color: var(--red);
}
.ConsoleRender_failedText__ipc5Q {
  color: var(--red);
}

.RenderIframe_previewWindowWebFrame__2mNZd {
  position: absolute;
  height: 100%;
  width: 100%;
  border: none;
}

.PreviewWindow_container__2uKyI {
  position: relative;
  width: 100%;
  height: 100%;
}

.CodeWindow_container__3kLsV {
  width: 100%;
}

.CodeWindow_tab__3yk3k {
  opacity: unset !important;
}

.PlayerCodePreviewWindow_playerWindowContentArea__17Lvf {
  height: calc(100% - 120px);
}
.PlayerCodePreviewWindow_layoutArea__3gR_p {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: stretch;
}

.PlayerCodePreviewWindow_layoutAreaRow__1HQfW {
  flex-direction: row !important;
}

.PlayerCodePreviewWindow_layoutAreaChild__2B3Vh {
  flex: 1 1 50%;
  align-self: stretch;
}

.PlayerCodePreviewWindow_layoutAreaRow__1HQfW .PlayerCodePreviewWindow_layoutAreaChild__2B3Vh:nth-child(2) {
  order: -1;
}

/**
  ChatWindow
*/
.ChatWindow_chatArea__Bm6Y- {
  width: 18%;
}

.ChatWindow_chatWindowContainer__POw6N {
  position: fixed;
  bottom: 0px;
  right: 0px;
  border: 2px black solid;
  max-width: 300px;
  max-height: 450px;
  padding: 0px;
  border-radius: 4px;
  box-shadow: 6px 6px 5px gray;
  -webkit-transition: box-shadow 1s;
  transition: box-shadow 1s;
  z-index: 2001;
}

.ChatWindow_chatHeader__1Cy4X {
  display: flex;
  align-items: center;
  color: var(--lightWhite);
  background-color: var(--mainBlue);
  justify-content: center;
  margin: 0;
  box-sizing: border-box;
  height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.ChatWindow_chatMinimised__3bK57 {
  padding: 0;

  z-index: 1001;
}

.ChatWindow_chatFooter__3YMsf {
  background-color: var(--lightWhite);
  color: var(--black);
  margin: 0;
  padding: 0 0 0 15px;
  box-sizing: border-box;
  height: 50px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ChatWindow_chatHeaderInfo__u2Whf {
  flex: 1 1 auto;
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}
.ChatWindow_chatHeaderInfo__u2Whf .ChatWindow_iconGroup__1rPE8 {
  flex: 0 1 auto;
}

.ChatWindow_chatHeaderControl__1dcN7 {
  flex: 0 1 auto;
  color: var(--lightWhite);
  float: right;
}
.ChatWindow_chatHeaderControl__1dcN7 .ChatWindow_icon__4t8lJ {
  cursor: pointer;
}

.ChatWindow_chatWindowMessageHeader__1PKoC {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
}

.ChatWindow_chatWindowMessageIcon__5v0Mm {
  margin-right: 8px;
}

.ChatWindow_chatWindowConnectedPlayers__3SC8e {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 12px;
}

.ChatWindow_chatWindowMainArea__2eUCa {
  overflow-y: auto;
  height: 350px;
  background: rgba(255, 255, 255, 0.9) url(/images/logo.png) no-repeat center;
  background-size: 100px;
  border-top: 1px black solid;
  border-bottom: 1px black solid;
}

.ChatWindow_chatWindowMessageList__AF0ip {
  list-style-type: none;
  padding: 0 0 10px 10px;
  margin: 0;
}

.ChatWindow_chatWindowMessageList__AF0ip li {
  display: flex;
}

.ChatWindow_chatWindowMessage__10Uv9 {
  border: 1px solid #02a8f3;
  background-color: rgba(2, 168, 243, 0.5);
  border-radius: 0 10px 10px 10px;
  padding: 3px 9px;
  margin-top: 12px;
  display: inline-block;
}

.ChatWindow_chatWindowMessageMe__1DyMN {
  border: 1px solid #e48414;
  background-color: rgba(228, 132, 20, 0.5);
  border-radius: 10px 0 10px 10px;
  margin-left: auto;
  margin-right: 12px;
}

.ChatWindow_chatWindowMessage__10Uv9 h4 {
  margin: 0;
}

.ChatWindow_chatWindowMainArea__2eUCa::-webkit-scrollbar-thumb {
  background-color: rgba(2, 168, 243, 0.5);
}
.ChatWindow_chatWindowMainArea__2eUCa::-webkit-scrollbar {
  width: 8px;
  background-color: var(--lightWhite);
}
.ChatWindow_chatWindowMainArea__2eUCa::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
  background-color: var(--lightWhite);
}
.ChatWindow_badge__3oz9Y {
  top: 0;
  right: 0;
}
.ChatWindow_textField__3SFQa {
  width: 65%;
  margin-right: 10px;
}
.ChatWindow_headerButton__1e9vr {
  padding: 0;
  color: var(--lightWhite);
  width: 24px;
  height: 24px;
}

.ChatWindow_messageListDiv__3MDgM {
  float: left;
  clear: both;
}

.ChatWindow_chatButton__rqkPL {
  background-color: var(--mainBlue);
}

.ChatWindow_chatIcon__2xDS0 {
  color: var(--lightWhite);
}
.ChatWindow_messageText__2-dnL {
  color: var(--black);
}

.NotificationBadge_badge__3beay {
  padding: 0;
}

.PlayerFooter_load__1UAA7,
.PlayerFooter_run__Xba4k {
  background-color: var(--mainGreen);
  color: var(--darkWhite);
}
.PlayerFooter_save__ZJaY6,
.PlayerFooter_submit__1OYdW {
  background-color: var(--mainBlue);
  color: var(--darkWhite);
}

.PlayerFooter_load__1UAA7 {
  margin-left: 1rem;
}

.PlayerFooter_playerFooterRunButton__2avo_ {
  margin-right: 2px;
}
.PlayerFooter_areaHeading__18Jam {
  display: flex;
  align-items: center;
  color: #e48414;
  background-color: #ffffff;
  justify-content: center;
  margin: 0;
  padding: 10px 15px;
  box-sizing: border-box;
  height: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.PlayerFooter_playerFooter__-efZ2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.PlayerFooter_right__2NtQg {
  display: flex;
  align-items: center;
}

.PlayerWindow_areaHeading__4kt-o {
  display: flex;
  align-items: center;
  color: #e48414;
  background-color: #ffffff;
  justify-content: center;
  margin: 0;
  padding: 10px 15px;
  box-sizing: border-box;
  height: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.PlayerWindow_fillHeight__3PhEC {
  height: 100%;
}

.PlayersArea_playerArea__22BzJ {
  display: flex;
  width: 80%;
}
.PlayersArea_fillHeight__kE46I {
  height: 100%;
}
.PlayersArea_playerWindow__2WaCa {
  width: 50%;
}
.PlayersArea_onePlayerWindow__2ugm7 {
  width: 100%;
}

.chip_chip__2v2xe {
  margin: 4px;
}

.PlayerInvitor_query__31mLy {
  color: var(--red);
  font-weight: bold;
}
.PlayerInvitor_input__1JOVV {
  position: relative;
  width: 28rem;
}
.PlayerInvitor_inputIcon__cy6n8 {
  position: absolute;
  top: 8px;
  left: 10px;
}
.PlayerInvitor_error__r20WN {
  color: var(--red);
  font-size: 10px;
}
.PlayerInvitor_stagedPlayersWrapper__3-J_B {
  display: flex;
  flex-wrap: wrap;
}
.PlayerInvitor_suggestionContent__Y_hxU {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}
.PlayerInvitor_name__1R2PN {
  margin-left: 12px;
  line-height: 45px;
}
.PlayerInvitor_suggestionContainer__2OHjd {
  display: flex;
  align-items: center;
  justify-content: center;
}
.PlayerInvitor_message__2tbwl {
  margin-bottom: 20px;
  text-align: center;
}

.AddGroupDialog_dialogBody__1tCwR {
  min-height: 500px;
  margin-top: 15px;
}
.AddGroupDialog_playerInvitor__ql6rM {
  min-height: 100px;
}

.Container_container__1VLOQ {
  width: 80%;
  margin: 30px auto;
  text-align: center;
}

.EditAvatarModal_paper__9Tj3i {
  width: 80vw;
  height: 80vh;
  max-width: unset;
  background-color: var(--darkWhite);
  color: var(--darkBlue);
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.EditAvatarModal_title__1S2La {
  color: inherit;
  font-size: 2rem;
  width: 80%;
  display: flex;
  justify-content: center;
}
.EditAvatarModal_content__3SLgc {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 60%;
  flex-wrap: wrap;
}
.EditAvatarModal_actions__2cNN9 {
  width: 100%;
  justify-content: center;
}
.EditAvatarModal_textfieldContainer__18mka {
  width: 100%;
  display: flex;
  justify-content: center;
}
.EditAvatarModal_textfield__Dcb7Q {
  width: 50%;
}
.EditAvatarModal_files__3vQxx {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.EditAvatarModal_friendsContainer__2wLD5,
.EditAvatarModal_sendContainer__1dufG,
.EditAvatarModal_pendingContainer__1IQ_J,
.EditAvatarModal_requestsContainer__2UohS {
  height: 48%;
  width: 35%;

  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.EditAvatarModal_sendContainer__1dufG {
  justify-content: space-between;
}

.EditAvatarModal_span__3PBFJ {
  margin-top: 5rem;
  color: #8080808f;
}

.profile-picture_image__3Q1bg {
  height: 100%;
  text-align: center;
  position: relative;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  margin: auto;
}

.profile-picture_icon__39N-f {
  color: white;
}

.profile-picture_tooltip__24dKV:hover {
  -webkit-filter: grayscale(90%);
          filter: grayscale(90%);
}

/*
  Hover tooltip
*/

.profile-picture_tooltiptext__-pm8t {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 8px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.profile-picture_tooltip__24dKV:hover .profile-picture_tooltiptext__-pm8t {
  visibility: visible;
  opacity: 1;
}

.profile-picture_frameStyle__1hfIT {
  overflow: hidden;
  border: 2px solid #00b17d;
  border-radius: 10px;
  position: relative;
  text-align: center;
  background-image: -webkit-gradient(
    linear,
    left bottom, left top,
    from(#ff6a5b),
    color-stop(#eb5161),
    color-stop(#d33b67),
    color-stop(#b7296a),
    to(#981c6b)
  );
  background-image: linear-gradient(
    to top,
    #ff6a5b,
    #eb5161,
    #d33b67,
    #b7296a,
    #981c6b
  );
  position: relative;
  width: 40%;
  height: 99%;
}
.profile-picture_pictureStyle__2IbUq {
  width: 95%;
  margin: 2.5% auto;
}

.profile-picture_editAvatar__1APN2 {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--mainGreen);
  color: var(--darkWhite);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;

  padding: 1rem;
  -webkit-transition: -webkit-transform 0.3s linear;
  transition: -webkit-transform 0.3s linear;
  transition: transform 0.3s linear;
  transition: transform 0.3s linear, -webkit-transform 0.3s linear;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.profile-picture_popIn__1Zil7 {
  -webkit-transform: translateX(0) !important;
          transform: translateX(0) !important;
}

.profile-picture_popIn__1Zil7:hover {
  background-color: var(--darkBlue);
}

.addroom_paper__34zbr {
  width: 80vw;
  height: 80vh;
  max-width: unset;
  background-color: var(--darkWhite);
  color: var(--darkBlue);
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.addroom_invite__3IAMP {
  display: flex;
  flex-direction: column;
}
.addroom_challenge__2JoYf {
  text-align: center;
  width: 45%;
}
.addroom_title__1endp {
  color: inherit;
  font-size: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.addroom_text__rq_pE {
  color: var(--lightWhite);
}
.addroom_content__1UqqM {
  display: flex;
  width: 100%;
  flex: 1 0 auto;
  flex-direction: row;
  justify-content: center;
}
.addroom_actions__1G9P5 {
  width: 100%;
  justify-content: center;
}

/* * {
  transition: all 0.3s ease;
} */
.room-list-container_listContainer__DF3lN {
  width: 100%;
  height: auto;
}
.room-list-container_listItem__1na4A {
  width: 100%;
  min-height: 70px;
  background-color: white;
  margin: 10px 0px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  border-radius: 3px;
}

.room-list-container_row__3KM7H {
  cursor: pointer;
}
.room-list-container_row__3KM7H:hover {
  background-color: var(--darkBlue);
}

/*List item styling */

/*Player Info*/
.room-list-container_playerInfo__NhRSb {
  width: 30%;
  height: 100%;
  border-right: 2px solid rgba(0, 0, 0, 0.14);
  text-align: center;
  min-height: 70px;
  position: relative;
}
.room-list-container_playerInfoContent__2rPcd {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.room-list-container_challengeName__3SMqb {
  font-size: 15px;
  letter-spacing: 1px;
  color: #292929;
  font-weight: 400;
}
.room-list-container_players__1yBFQ {
  color: #302f2f;
  margin: 2px 7px;
  font-weight: 200;
  display: inline-block;
}
.room-list-container_players__1yBFQ,
.room-list-container_playerIcon__220yw {
  display: inline;
}
.room-list-container_playerIcon__220yw {
  position: relative;
  top: 3px;
  width: 19px !important;
  height: 19px !important;
}

/*Challenge Info */
.room-list-container_challengeInfo__2SYld {
  /*width: 50%; <- for when there is remove button too*/
  width: 60%;
  height: 100%;
  border-right: 2px solid rgba(0, 0, 0, 0.14);
  min-height: 70px;
  display: flex;
}

.room-list-container_centre__34fxt {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.room-list-container_languages__2dx9y {
  width: 30%;
  min-height: 70px;
  text-align: center;
  position: relative;
}
.room-list-container_languageItems__2NUmM {
  font-size: 12px;
  display: inline-block;
  font-weight: 300;
  margin: 0px 5px;
}
.room-list-container_progress__-kAUp {
  width: 70%;
  min-height: 70px;
  text-align: center;
  position: relative;
}
.room-list-container_actionButton__3zK7N {
  width: 10%;
  min-height: 70px;
  text-align: center;
  cursor: pointer;
}
.room-list-container_actionButton__3zK7N:hover > h2 {
  color: white;
  letter-spacing: 4px;
}
.room-list-container_actionButton__3zK7N h2 {
  font-size: 15px;
  margin-top: 20%;
}
.room-list-container_join__2biz4:hover {
  background-color: #23ddae;
}
.room-list-container_remove__YH4_m:hover {
  background-color: #e66854;
}
.room-list-container_icon__1DNEp {
  display: block;
  margin: auto;
}
.room-list-container_paper__ES8N9 {
  background-color: #b6feff6e;
}
.room-list-container_buttons__3Xce0 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
}
.room-list-container_iconHeader__3qMyh {
  background-color: #00b57999;
}
.room-list-container_iconH__23DRb {
  text-align: center;
}
.room-list-container_icons__3Fqca {
  color: var(--darkWhite);
}
.room-list-container_text__38Arg {
  color: var(--darkWhite);
}
.room-list-container_center__RhT-M {
  text-align: center;
}
.room-list-container_selected__2_uEj {
  background-color: var(--mainBlue) !important;
}

.room-list-container_joinButton__16dtm {
  position: absolute;
  bottom: 0;
  right: -8rem;
  background-color: var(--mainGreen);
  color: var(--darkWhite);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;

  padding: 1rem;
  -webkit-transition: -webkit-transform 0.4s linear;
  transition: -webkit-transform 0.4s linear;
  transition: transform 0.4s linear;
  transition: transform 0.4s linear, -webkit-transform 0.4s linear;
}

.room-list-container_joinButton__16dtm:hover {
  background-color: #000e2fa6;
}

.room-list-container_popIn__38vP4 {
  -webkit-transform: translateX(-200%) !important;
          transform: translateX(-200%) !important;
}

.room-list-container_popIn__38vP4:hover {
  background-color: var(--darkBlue);
}

.room-list-container_last__1Bjma {
  position: relative;
  overflow: hidden;
}

.FriendsModal_paper__10G0K {
  width: 80vw;
  height: 80vh;
  max-width: unset;
  background-color: var(--darkWhite);
  color: var(--darkBlue);
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.FriendsModal_title__1BiyR {
  color: inherit;
  font-size: 2rem;
  width: 80%;
  display: flex;
  justify-content: center;
}
.FriendsModal_content__VkJrH {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 60%;
  flex-wrap: wrap;
}
.FriendsModal_actions__1joN2 {
  width: 100%;
  justify-content: center;
}
.FriendsModal_textfieldContainer__FvEnV {
  width: 100%;
  display: flex;
  justify-content: center;
}
.FriendsModal_textfield__1G88O {
  width: 50%;
}
.FriendsModal_files__1DnR9 {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.FriendsModal_friendsContainer__cuVBo,
.FriendsModal_sendContainer__2sK9N,
.FriendsModal_pendingContainer__3PFBj,
.FriendsModal_requestsContainer__2DULe {
  height: 48%;
  width: 35%;

  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FriendsModal_sendContainer__2sK9N {
  justify-content: space-between;
}

.FriendsModal_span__2cSD_ {
  margin-top: 5rem;
  color: #8080808f;
}

.TableContainer_button__1Ffmg {
  background-color: var(--mainGreen);
  margin: 0.5rem;
}

.TableContainer_icon__3VyNN {
  color: var(--darkWhite);
}

.TableContainer_title__1_ESx {
}

.TableContainer_header__3dX66 {
  width: 100%;
  height: 7vh;
  color: var(--darkWhite);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;

  border-radius: 5px;
}

.RoomPage_infoBox__1yH6f {
  margin: 25px 0;
  border-radius: 10px;
  color: white;
  text-align: center;
  font-size: 1.2rem;
  background-color: #00b17dbd;
  padding: 0.8rem;
}

.RoomPage_infoBox__1yH6f p {
  padding-top: 6px;
  padding-bottom: 6px;
}

.RoomPage_kickstarterContent__3jIFE {
  display: inline-block;
  background-color: #009f7f;
  padding: 15px 20px;

  box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.RoomPage_btn__3bPQb {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.RoomPage_kickstarterButtonA__1KDmq {
  font-weight: bold;
  padding: 7px 35px;
  border: 2px solid white !important;
  border-radius: 10px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 10px;
}
.RoomPage_kickstarterButtonA__1KDmq:hover {
  background-color: white;
  color: #14e06e;
}
.RoomPage_kickstarterButtonB__16067 {
  font-weight: bold;
  padding: 7px 35px;
  border: 2px solid #14e06e !important;
  border-radius: 10px;
  color: #14e06e;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.RoomPage_kickstarterButtonB__16067:hover {
  background-color: #14e06e;
  color: white;
}
.RoomPage_kickstarterContent__3jIFE img {
  height: 30px;
  padding: 0 5px;
}
.RoomPage_kickstarterContent__3jIFE h1 {
  color: var(--darkWhite);
}
.RoomPage_kickstarterContent__3jIFE h2 {
  color: white;
}
.RoomPage_container__1kaHu {
  background-image: url("/images/patterns/stars.png");
  background-size: contain;
  padding-bottom: 1rem;
}
.RoomPage_top__2tH8j {
  display: flex;
  padding-top: 1rem;
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.RoomPage_textHeader__3ap__ {
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.Form_button__1bKWC {
  margin: 20px auto;
}
.Form_error__2QbGo {
  font-size: 0.8em;
  color: var(--red);
}

.Page_container__2Le_d {
  width: 80%;
  text-align: center;
  margin: auto;
  padding: 10px 30px;
}
,
.Page_footnote__2AV31 {
  font-size: 0.8em;
  margin: 10px auto;
  text-align: start;
}
,
.Page_separator__GAOrD {
  margin: auto 10px;
}

.PlayerName_container__3j3SP {
  padding: 10px 0px 0px 0px;
}
.PlayerName_name__3SloW {
  margin: 0px;

  color: #f27f00;
}

.ContentBox_slider__2yZPb {
  border-radius: 4px;
  position: relative;
  background-color: rgba(2, 168, 243, 0.5);
  color: var(--darkWhite);
}

.ContentBox_rule__2P4nv {
  width: 100%;
  height: 2px;
  backgroundcolor: #2b2b2b;
  outline: none;
  border: none;
  margin: 10px 0px 25px 0px;
}

.ContentBox_text__LkevE {
  font-size: 22px;

  margin: 0px;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.HorizontalImageSlider_container__1btYg {
  text-align: center;
}
.HorizontalImageSlider_image__2cl-d {
  margin: auto;
}
.HorizontalImageSlider_label__2nJwX {
  margin: 0;
}

.slick-list {
  height: 50vh;
}

.TextInputDialog_paper__34o65 {
  width: 80vw;
  height: 80vh;
  max-width: unset;
  background-color: var(--darkWhite);
  color: var(--darkBlue);
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.TextInputDialog_title__-MRE6 {
  color: inherit;
  font-size: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.TextInputDialog_content__2XkFK {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 40%;
}
.TextInputDialog_actions__2Kw73 {
  width: 100%;
  justify-content: center;
}
.TextInputDialog_textfieldContainer__1tbKr {
  width: 100%;
  display: flex;
  justify-content: center;
}
.TextInputDialog_textfield__1jCSg {
  width: 50%;
}
.TextInputDialog_files__2peNq {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Modal_normal__7ifO3 {
  primary: var(--grey);
  background: var(--black);
}
.Modal_chosen__2-WAZ {
  primary: var(--mainBlue);
  background: var(--lightWhite);
}
.Modal_error__1VVPS {
  color: var(--red);
}
.Modal_container__1-g9V {
  position: relative;
}
.Modal_refresh__lI9Yg {
  display: inline-block;
  position: relative;
}

.FileChip_chip__1tJrG {
  margin: 4px;
}

.Feedback_sliderText__2Y2uK {
  text-align: center;
  margin-top: -30px;
}

playerInvitor {
  min-height: 500px;
}
.InvitePlayerModal_paper__3X-Pp {
  width: 80vw;
  height: 80vh;
  max-width: unset;
  background-color: var(--darkWhite);
  color: var(--darkBlue);
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.InvitePlayerModal_title__C6Lbd {
  color: inherit;
  font-size: 2rem;
  width: 80%;
  display: flex;
  justify-content: center;
}
.InvitePlayerModal_content__OQszl {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 60%;
  flex-wrap: wrap;
}
.InvitePlayerModal_actions__WJJFi {
  width: 100%;
  justify-content: center;
}
.InvitePlayerModal_textfieldContainer__27drS {
  width: 100%;
  display: flex;
  justify-content: center;
}
.InvitePlayerModal_textfield__12qpa {
  width: 50%;
}
.InvitePlayerModal_files__2qqGX {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.InvitePlayerModal_friendsContainer__2GWdA,
.InvitePlayerModal_sendContainer__2z2mz,
.InvitePlayerModal_pendingContainer__1RDsd,
.InvitePlayerModal_requestsContainer__Cukxv {
  height: 48%;
  width: 35%;

  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.InvitePlayerModal_sendContainer__2z2mz {
  justify-content: space-between;
}

.InvitePlayerModal_span__3xTdk {
  margin-top: 5rem;
  color: #8080808f;
}

.RegisterDialog_messageStyle__1ipF3 {
  margin-top: 15px;
}

