.paper {
  width: 80vw;
  height: 80vh;
  max-width: unset;
  background-color: var(--darkWhite);
  color: var(--darkBlue);
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.invite {
  display: flex;
  flex-direction: column;
}
.challenge {
  text-align: center;
  width: 45%;
}
.title {
  color: inherit;
  font-size: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.text {
  color: var(--lightWhite);
}
.content {
  display: flex;
  width: 100%;
  flex: 1 0 auto;
  flex-direction: row;
  justify-content: center;
}
.actions {
  width: 100%;
  justify-content: center;
}
