.playerWindowContentArea {
  height: calc(100% - 120px);
}
.layoutArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: stretch;
}

.layoutAreaRow {
  flex-direction: row !important;
}

.layoutAreaChild {
  flex: 1 1 50%;
  align-self: stretch;
}

.layoutAreaRow .layoutAreaChild:nth-child(2) {
  order: -1;
}
