.button {
  background-color: var(--mainGreen);
  margin: 0.5rem;
}

.icon {
  color: var(--darkWhite);
}

.title {
}

.header {
  width: 100%;
  height: 7vh;
  color: var(--darkWhite);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;

  border-radius: 5px;
}
