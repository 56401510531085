.slider {
  border-radius: 4px;
  position: relative;
  background-color: rgba(2, 168, 243, 0.5);
  color: var(--darkWhite);
}

.rule {
  width: 100%;
  height: 2px;
  backgroundcolor: #2b2b2b;
  outline: none;
  border: none;
  margin: 10px 0px 25px 0px;
}

.text {
  font-size: 22px;

  margin: 0px;
  letter-spacing: 1px;
  text-transform: capitalize;
}
