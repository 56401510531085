.playerArea {
  display: flex;
  width: 80%;
}
.fillHeight {
  height: 100%;
}
.playerWindow {
  width: 50%;
}
.onePlayerWindow {
  width: 100%;
}
