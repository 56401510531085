.information {
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
}

.informationInstruction {
  border: 1px dodgerblue solid;
  margin: 12px;
  padding: 0 5px;
}

/*For markdown*/
.information pre {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  word-wrap: normal;
  padding: 2px 5px;
  border-radius: 3px;
}

.information p code {
  padding: 2px 4px;
  font-size: 90%;
  color: #3f3f3f;
  background-color: #f8f8f8;
  white-space: nowrap;
  border-radius: 4px;
}

.information table {
  display: block;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.information tr {
  background-color: #fff;
  border-top: 1px solid black;
}

.information tr:nth-child(2n) {
  /*background-color: #f8f8f8;*/
}

.information th,
td {
  padding: 6px 13px;
  border: 1px solid black;
}
