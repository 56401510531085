.areaHeading {
  display: flex;
  align-items: center;
  color: #e48414;
  background-color: #ffffff;
  justify-content: space-between;
  margin: 0;
  padding: 10px 15px;
  box-sizing: border-box;
  height: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.playerHeaderName {
  font-size: 30px;
  margin-right: 8px;
  font-family: "Fjalla One", sans-serif;
}
.status {
  min-width: 95px;
}
.center {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  justify-content: center;
}
