:root {
  --mainBlue: #4a90e2;
  --lightBlue: ;
  --blue: #001c55;
  --darkBlue: #000e2f;

  --mainGreen: #00b17d;
  --lightGreen: ;
  --darkGreen: ;

  --black: #000818;

  --lightWhite: #f7f7f7;
  --darkWhite: #f2f2f2;

  --grey: #d8d8d8;

  --red: #cc0000;
}
