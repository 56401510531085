.fillHeight {
  height: 100%;
}

.informationAreaSection {
  height: calc(100% - 120px);
  overflow-y: scroll;
}
.informationAreaSection::-webkit-scrollbar-thumb {
  background-color: rgba(2, 168, 243, 0.5);
}
.informationAreaSection::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
.informationAreaSection::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.informationAreaSection pre::-webkit-scrollbar-thumb {
  background-color: rgba(2, 168, 243, 0.5);
}
.informationAreaSection pre::-webkit-scrollbar {
  height: 8px;
  background-color: #f5f5f5;
}
.informationAreaSection pre::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.areaHeading {
  display: flex;
  align-items: center;
  color: #e48414;
  background-color: #ffffff;
  justify-content: center;
  margin: 0;
  padding: 10px 15px;
  box-sizing: border-box;
  height: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.informationAreaFooter {
  background-repeat: no-repeat;
  background-size: auto 90%;
  background-position: center;
  background-image: url("/public/images/logo.png");
}
