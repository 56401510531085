.paper {
  width: 80vw;
  height: 80vh;
  max-width: unset;
  background-color: var(--darkWhite);
  color: var(--darkBlue);
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.title {
  color: inherit;
  font-size: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 40%;
}
.actions {
  width: 100%;
  justify-content: center;
}
.textfieldContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.textfield {
  width: 50%;
}
.files {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
