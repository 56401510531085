/* The browser window */
.container {
  border: 3px solid #f1f1f1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 100%;
  overflow: hidden;
}

/* Container for columns and the top "toolbar" */
.row {
  padding: 10px;
  background: #f1f1f1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

/* Create three unequal columns that floats next to each other */
.column {
  float: left;
}

.left {
  width: 15%;
}

.right {
  width: 10%;
}

.middle {
  width: 75%;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

/* Three dots */
.dot {
  margin-top: 4px;
  height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.dot:first-child {
  background: #5ac05a;
}
.dot:nth-child(2) {
  background: #fdd800;
}
.dot:last-child {
  background: #ed594a;
}

/* Style the input field */
.urlField {
  width: 100%;
  border-radius: 3px;
  border: none;
  background-color: white;
  margin-top: -2px;
  color: #666;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Three bars (hamburger menu) */
.bar {
  width: 17px;
  height: 3px;
  background-color: #aaa;
  margin: 3px 0;
  display: block;
}

.menu {
  float: right;
}

/* Page content */
.content {
  position: relative;
  height: calc(100% - 50px);
  width: 100%;
}
