@import url(https://fonts.googleapis.com/css?family=Varela+Round);
@import url(
  https://fonts.googleapis.com/css?family=Open+Sans:300,
  400,
  600,
  700
);
/* Fonts */
p,
h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", sans-serif;
}

/*ColonelDuck*/
body {
  margin: 0;
  background-color: #e3e3e3;
  font-family: "Montserrat", sans-serif;
  /*"Helvetica Neue"*/
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0px;
  padding: 0px;
}

.background {
  background-image: url(/images/patterns/stars.png);
  background-size: contain;
}

.fill-height {
  height: 100%;
}

/**
  GameContainer
*/
.game_container {
  display: flex;
  height: 93vh;
}

/**
  AreaHeader
*/
.area-heading {
  display: flex;
  align-items: center;
  color: #e48414;
  background-color: #ffffff;
  justify-content: center;
  margin: 0;
  padding: 10px 15px;
  box-sizing: border-box;
  height: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/**
  AreaFooter
*/
.area-footer {
  background-color: #ffffff;
  color: #e48414;
  margin: 0;
  padding: 5px 18px;
  box-sizing: border-box;
  height: 60px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/**
  PlayerArea
*/
.player_area {
  display: flex;
  width: 80%;
}

.layout_area {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: stretch;
}

.layout_area_row {
  flex-direction: row !important;
}

.layout_area_row .layout_area_child:nth-child(2) {
  order: -1;
}

.layout_area_child {
  flex: 1 1 50%;
  align-self: stretch;
}

/**
  PlayerWindow
*/
.one_player_window {
  width: 100%;
}

.player_window {
  width: 50%;
}

.player_window-content_area {
  height: calc(100% - 120px);
}

.player_header-name {
  font-size: 30px;
  margin-right: 8px;
  font-family: "Fjalla One", sans-serif;
}

/**
  PlayerFooter
*/
.player_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.player_footer-centred {
  justify-content: center;
}

.player_footer-run_button {
  margin-right: 2px;
}

/**
  PreviewWindow
*/
.preview_window {
  position: relative;
  height: 100%;
}

.preview_window-web {
  height: 100%;
}

.preview_window-web-frame {
  position: absolute;
  height: 100%;
  width: 100%;
  border: none;
}

.preview_window-console {
  overflow-y: auto;
}

/**
  InformationArea
*/
/*TODO: Check flexBasis support*/
.information_area {
  width: 20%;
  overflow-x: hidden;
  overflow-y: hidden;
}

._area-section {
  height: calc(100% - 120px);
  overflow-y: scroll;
}

.information_area-footer {
  background-repeat: no-repeat;
  background-size: auto 90%;
  background-position: center;
  background-image: url("/public/images/logo.png");
}

/*Setup scrollbar origin state*/
/*TODO: unfortunately, it seems like transition doesn't work on these selectors..*/
/*This approach was tried on normal CSS selectors and worked fine..*/
/*e.g. http://jsfiddle.net/derekstory/fTAUk/4/*/
.information_area-section::-webkit-scrollbar-thumb,
.chat_window-main_area::-webkit-scrollbar-thumb {
  background-color: rgba(2, 168, 243, 0.5);
}

.information_area-section::-webkit-scrollbar,
.chat_window-main_area::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.information_area-section::-webkit-scrollbar-track,
.chat_window-main_area::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.information_area-section pre::-webkit-scrollbar-thumb {
  background-color: rgba(2, 168, 243, 0.5);
}

.information_area-section pre::-webkit-scrollbar {
  height: 8px;
  background-color: #f5f5f5;
}

.information_area-section pre::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

/**
  Information
*/
.information {
  margin: 0 10px 0 10px;
}

.information-instruction {
  border: 1px dodgerblue solid;
  margin: 12px;
  padding: 0 5px;
}

/*For markdown*/
.information pre {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  word-wrap: normal;
  padding: 2px 5px;
  border-radius: 3px;
}

.information p code {
  padding: 2px 4px;
  font-size: 90%;
  color: #3f3f3f;
  background-color: #f8f8f8;
  white-space: nowrap;
  border-radius: 4px;
}

.information table {
  display: block;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.information tr {
  background-color: #fff;
  border-top: 1px solid black;
}

.information tr:nth-child(2n) {
  /*background-color: #f8f8f8;*/
}

.information th,
td {
  padding: 6px 13px;
  border: 1px solid black;
}

/*end - For markdown*/

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 100%;
  padding: 10px 20px 10px 40px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container--open {
  position: absolute;
  top: 51px;
  left: -28px;
  width: 500px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0;
  list-style-type: none;
  height: 50px;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #0c7eaf;
  color: #fff;
}

.suggestion-container {
}

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  height: 100%;
}

.react-autosuggest__suggestion img {
  height: 50px;
}
.suggestion-content > img {
  height: 50px;
}

.name {
  margin-left: 12px;
  line-height: 45px;
}

.react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}
.react-autosuggest__suggestions-list {
  padding: 0;
}

.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #777;
}

/** Invite Button **/
