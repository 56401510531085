.image {
  height: 100%;
  text-align: center;
  position: relative;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  margin: auto;
}

.icon {
  color: white;
}

.tooltip:hover {
  filter: grayscale(90%);
}

/*
  Hover tooltip
*/

.tooltiptext {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 8px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: opacity 1s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.frameStyle {
  overflow: hidden;
  border: 2px solid #00b17d;
  border-radius: 10px;
  position: relative;
  text-align: center;
  background-image: linear-gradient(
    to top,
    #ff6a5b,
    #eb5161,
    #d33b67,
    #b7296a,
    #981c6b
  );
  position: relative;
  width: 40%;
  height: 99%;
}
.pictureStyle {
  width: 95%;
  margin: 2.5% auto;
}

.editAvatar {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--mainGreen);
  color: var(--darkWhite);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;

  padding: 1rem;
  transition: transform 0.3s linear;
  transform: translateX(100%);
}

.popIn {
  transform: translateX(0) !important;
}

.popIn:hover {
  background-color: var(--darkBlue);
}
