.logo {
  height: 4vh;
}
.icon {
  color: var(--darkBlue);
}
.content {
  flex-grow: 1;
}
.button {
  color: white;
}
.title {
  text-align: center;
  font-size: 14px;
}
.nav {
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  width: 96%;
  text-decoration: none;
}
.active {
  text-decoration: none;
}
.drawer {
  width: 30vw;
}

.linkList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  width: 91%;
  padding: 1rem;
  text-decoration: none;
}
