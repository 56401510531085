.container {
  height: 40vh;
  width: 25vw;
  box-sizing: border-box;
  margin: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  filter: grayscale(0.7);
  overflow: hidden;
}

.imgSection {
  flex: 1 0 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionSection {
  position: relative;
  text-decoration: none;
  flex: 1 0 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container:nth-child(4n + 1) {
  background-image: linear-gradient(
    to right top,
    #6882c0,
    #5f6aa9,
    #575292,
    #4f3a79,
    #472260
  );
}
.container:nth-child(4n + 1):hover {
  filter: grayscale(0);
  background-image: linear-gradient(
    to right top,
    #6882c0,
    #5f6aa9,
    #575292,
    #4f3a79,
    #472260
  );
}
.container:nth-child(4n + 2):hover {
  filter: grayscale(0);
  background-image: linear-gradient(
    to right bottom,
    #dd5668,
    #e26454,
    #e17642,
    #d88a32,
    #ca9d29
  );
}
.container:nth-child(4n + 3):hover {
  filter: grayscale(0);
  background-image: linear-gradient(
    to left bottom,
    #34285a,
    #38296d,
    #3b2a81,
    #3e2b95,
    #402baa
  );
}
.container:nth-child(4n + 4):hover {
  filter: grayscale(0);
  background-image: linear-gradient(
    to top,
    #ff6a5b,
    #eb5161,
    #d33b67,
    #b7296a,
    #981c6b
  );
}
.container:nth-child(4n + 2) {
  background-image: linear-gradient(
    to right bottom,
    #dd5668,
    #e26454,
    #e17642,
    #d88a32,
    #ca9d29
  );
}
.container:nth-child(4n + 3) {
  background-image: linear-gradient(
    to left bottom,
    #34285a,
    #38296d,
    #3b2a81,
    #3e2b95,
    #402baa
  );
}
.container:nth-child(4n + 4) {
  background-image: linear-gradient(
    to top,
    #ff6a5b,
    #eb5161,
    #d33b67,
    #b7296a,
    #981c6b
  );
}

.selected {
  border: 5px solid var(--mainGreen);
  filter: grayscale(0);
}

.character {
  transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform: scale(1);
}

.characterSelected {
  transform: scale(1.2);
}

.goPlay {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--mainGreen);
  color: var(--darkWhite);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;

  padding: 1rem;
  transition: transform 0.3s linear;
  transform: translateX(100%);
}

.goPlay:hover {
  background-color: var(--blue);
}

.popIn {
  transform: translateX(0) !important;
}

.name {
  color: var(--darkWhite);

  font-size: 2rem;
}
/*
#6882c0
#773aa1

#5c41b8
#5947b2

#dd5668
#ca9d29

#ff6a5b
#981c6b */
