/**
  ChatWindow
*/
.chatArea {
  width: 18%;
}

.chatWindowContainer {
  position: fixed;
  bottom: 0px;
  right: 0px;
  border: 2px black solid;
  max-width: 300px;
  max-height: 450px;
  padding: 0px;
  border-radius: 4px;
  box-shadow: 6px 6px 5px gray;
  -webkit-transition: box-shadow 1s;
  transition: box-shadow 1s;
  z-index: 2001;
}

.chatHeader {
  display: flex;
  align-items: center;
  color: var(--lightWhite);
  background-color: var(--mainBlue);
  justify-content: center;
  margin: 0;
  box-sizing: border-box;
  height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.chatMinimised {
  padding: 0;

  z-index: 1001;
}

.chatFooter {
  background-color: var(--lightWhite);
  color: var(--black);
  margin: 0;
  padding: 0 0 0 15px;
  box-sizing: border-box;
  height: 50px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chatHeaderInfo {
  flex: 1 1 auto;
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}
.chatHeaderInfo .iconGroup {
  flex: 0 1 auto;
}

.chatHeaderControl {
  flex: 0 1 auto;
  color: var(--lightWhite);
  float: right;
}
.chatHeaderControl .icon {
  cursor: pointer;
}

.chatWindowMessageHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
}

.chatWindowMessageIcon {
  margin-right: 8px;
}

.chatWindowConnectedPlayers {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 12px;
}

.chatWindowMainArea {
  overflow-y: auto;
  height: 350px;
  background: rgba(255, 255, 255, 0.9) url(/images/logo.png) no-repeat center;
  background-size: 100px;
  border-top: 1px black solid;
  border-bottom: 1px black solid;
}

.chatWindowMessageList {
  list-style-type: none;
  padding: 0 0 10px 10px;
  margin: 0;
}

.chatWindowMessageList li {
  display: flex;
}

.chatWindowMessage {
  border: 1px solid #02a8f3;
  background-color: rgba(2, 168, 243, 0.5);
  border-radius: 0 10px 10px 10px;
  padding: 3px 9px;
  margin-top: 12px;
  display: inline-block;
}

.chatWindowMessageMe {
  border: 1px solid #e48414;
  background-color: rgba(228, 132, 20, 0.5);
  border-radius: 10px 0 10px 10px;
  margin-left: auto;
  margin-right: 12px;
}

.chatWindowMessage h4 {
  margin: 0;
}

.chatWindowMainArea::-webkit-scrollbar-thumb {
  background-color: rgba(2, 168, 243, 0.5);
}
.chatWindowMainArea::-webkit-scrollbar {
  width: 8px;
  background-color: var(--lightWhite);
}
.chatWindowMainArea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
  background-color: var(--lightWhite);
}
.badge {
  top: 0;
  right: 0;
}
.textField {
  width: 65%;
  margin-right: 10px;
}
.headerButton {
  padding: 0;
  color: var(--lightWhite);
  width: 24px;
  height: 24px;
}

.messageListDiv {
  float: left;
  clear: both;
}

.chatButton {
  background-color: var(--mainBlue);
}

.chatIcon {
  color: var(--lightWhite);
}
.messageText {
  color: var(--black);
}
