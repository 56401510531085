.load,
.run {
  background-color: var(--mainGreen);
  color: var(--darkWhite);
}
.save,
.submit {
  background-color: var(--mainBlue);
  color: var(--darkWhite);
}

.load {
  margin-left: 1rem;
}

.playerFooterRunButton {
  margin-right: 2px;
}
.areaHeading {
  display: flex;
  align-items: center;
  color: #e48414;
  background-color: #ffffff;
  justify-content: center;
  margin: 0;
  padding: 10px 15px;
  box-sizing: border-box;
  height: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.playerFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.right {
  display: flex;
  align-items: center;
}
