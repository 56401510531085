.normal {
  primary: var(--grey);
  background: var(--black);
}
.chosen {
  primary: var(--mainBlue);
  background: var(--lightWhite);
}
.error {
  color: var(--red);
}
.container {
  position: relative;
}
.refresh {
  display: inline-block;
  position: relative;
}
