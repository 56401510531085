.fillHeight {
  height: 100%;
}
.previewWindowConsole {
  overflow-y: auto;
}
.coding {
  width: 100%;
  height: 0px;
  background-color: transparent;
}
.barIcon {
  width: 20px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  vertical-align: top;
  position: relative;
  /* top: 50%; */
  /* transform: translateY(-50%); */
  margin-left: 20px;
}
.statusText {
  display: inline-block;
  position: relative;
  /* top: 50%; */
  /* transform: translateY(-50%); */
  margin-left: 10px;
  color: white;
  font-weight: 300;
  letter-spacing: 2px;
}
.codeOutput {
  color: var(--lightWhite);
  padding: 5px 0px 5px 20px;
  whitespace: pre-wrap;
}
.console {
  background-color: var(--grey);
  border-right: 2px solid var(--darkWhite);
  border-left: 2px solid var(--darkWhite);
}
.baseBar {
  height: 3rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.output {
  padding: 1rem;
}
.passed {
  background-color: var(--mainGreen);
}
.failed {
  background-color: var(--red);
}
.failedText {
  color: var(--red);
}
