.areaHeading {
  display: flex;
  align-items: center;
  color: #e48414;
  background-color: #ffffff;
  justify-content: center;
  margin: 0;
  padding: 10px 15px;
  box-sizing: border-box;
  height: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.fillHeight {
  height: 100%;
}
