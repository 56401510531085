.container {
  display: flex;
  flex-wrap: wrap;
  /* width: 80%; */
  margin: 0 auto;
  padding-top: 4rem;
  min-height: 100%;
}
.page {
  width: 100vw;
  min-height: 100vh;
  background-color: var(--darkBlue);
}
.header {
  width: 100%;
  height: 7vh;
  background-color: var(--mainBlue);
  color: var(--darkWhite);
  margin-left: 1%;
  margin-right: 1%;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  padding-left: 4%;
}
.caption {
  width: 100%;
  height: 7vh;
  margin-left: 1%;
  margin-right: 1%;
  display: flex;
  align-items: center;
  color: var(--darkWhite);
  padding-left: 4%;
}
