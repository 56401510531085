.container {
  width: 80%;
  text-align: center;
  margin: auto;
  padding: 10px 30px;
}
,
.footnote {
  font-size: 0.8em;
  margin: 10px auto;
  text-align: start;
}
,
.separator {
  margin: auto 10px;
}
