.query {
  color: var(--red);
  font-weight: bold;
}
.input {
  position: relative;
  width: 28rem;
}
.inputIcon {
  position: absolute;
  top: 8px;
  left: 10px;
}
.error {
  color: var(--red);
  font-size: 10px;
}
.stagedPlayersWrapper {
  display: flex;
  flex-wrap: wrap;
}
.suggestionContent {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}
.name {
  margin-left: 12px;
  line-height: 45px;
}
.suggestionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.message {
  margin-bottom: 20px;
  text-align: center;
}
